import React, {useEffect, useState} from 'react';
import {
	Box,
	Typography,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	Button,
	ButtonGroup,
} from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {es} from 'date-fns/locale';
import cronstrue from 'cronstrue';
import 'cronstrue/locales/es';

export const CronScheduler = (props) => {
	const {onChange, value} = props;
	const [selectFrame, setSelectFrame] = useState('daily');
	const thereIsValue = value && value !== '';
	const [messageTraslate, setMessageTraslate] = useState(null);
	const [GlobalValues, setGlobalValues] = useState(null);
	const [currentExpression, setCurrentExpression] = useState(null);
	const FREQ = [
		{text: 'Horas', value: 'hour'},
		{text: 'Días', value: 'daily'},
		{text: 'Semanas', value: 'weekly'},
		{text: 'Meses', value: 'monthly'},
	];

	useEffect(() => {
		if (value) {
			try {
				let expression = value;
				let description = cronstrue.toString(expression, {locale: 'es'});
				setMessageTraslate(description);
			} catch (err) {
				console.error('Error al interpretar la expresión cron:', err);
			}
		}
	}, [value]);

	const Frame = ({selectedFrame}) => {
		switch (selectedFrame) {
			case 'hour':
				return <HourFrame />;
			case 'daily':
				return <DailyFrame />;
			case 'weekly':
				return <WeeklyFrame />;
			case 'monthly':
				return <MonthlyFrame />;
			default:
				return <DailyFrame />;
		}
	};

	const chooseStructure = (event) => {
		setSelectFrame(event.target.value);
	};

	const setValidVField = (value) => {
		onChange(value); // Pasar el valor directamente

		try {
			let expression = value;
			let description = cronstrue.toString(expression, {locale: 'es'});
			setMessageTraslate(description);
		} catch (err) {
			console.error('Error al interpretar la expresión cron:', err);
		}
	};

	const HourFrame = () => {
		const [maxDate, setMaxDate] = useState(0);
		const [validValue, setValidValue] = useState(
			GlobalValues && GlobalValues.name === 'hour'
				? GlobalValues
				: {
						name: 'hour',
						every: {
							value: 3,
							valid: true,
						},
						hour: {
							value: null,
							valid: false,
							date: null,
						},
				  }
		);
		const [max, setMax] = useState(new Date(2025, 2, 10, maxDate, 59));
		const [errorMessage, setErrorMessage] = useState(null);

		useEffect(() => {
			setMax(new Date(2025, 2, 10, maxDate, 59));
		}, [maxDate]);

		const minTime = new Date(0, 0, 0, 5, 0); // 5 AM
		const maxTime = new Date(0, 0, 0, 20, 0); // 8 PM

		let range = [
			{text: 'Cada 1', value: 1},
			{text: 'Cada 2', value: 2},
			{text: 'Cada 3', value: 3},
			{text: 'Cada 4', value: 4},
			{text: 'Cada 6', value: 6},
			{text: 'Cada 8', value: 8},
			{text: 'Cada 12', value: 12},
		];

		const onChangeTimer = (newValue) => {
			if (newValue) {
				let fecha = newValue;
				let hora = fecha.getHours();
				let minuto = fecha.getMinutes();

				let hours = {hora, minuto};
				setValidValue({
					...validValue,
					hour: {
						value: hours,
						valid: true,
						date: fecha,
					},
				});
			}
		};

		const onChangeEvery = (event) => {
			let limit = {
				1: {max: 0},
				2: {max: 1},
				3: {max: 2},
				4: {max: 3},
				6: {max: 5},
				8: {max: 7},
				12: {max: 11},
			};
			setMaxDate(limit[event.target.value].max);
			setValidValue({
				...validValue,
				every: {
					value: event.target.value,
					valid: true,
				},
			});
		};

		const handleSave = () => {
			setErrorMessage(null);
      if (!validValue.hour || !validValue.hour.value || !validValue.hour.value.hora) {
        setErrorMessage('Debe seleccionar una hora');
        return;
      }
			const startHour = validValue.hour.value.hora;
			const startMinute = validValue.hour.value.minuto;
			const interval = parseInt(validValue.every.value);

			const endHour = 20; // 8 PM

			if (startHour < 5 || startHour > 20) {
				setErrorMessage(
					'La hora de inicio debe estar entre las 5 AM y las 8 PM.'
				);
				return;
			}

			// Calcular el número de actualizaciones
			let updates = [];
			let currentHour = startHour;
			while (currentHour <= endHour) {
				updates.push(currentHour);
				currentHour += interval;
			}

			if (updates.length > 5) {
				setErrorMessage(
					'El número de actualizaciones no puede ser mayor a 5 por día.'
				);
				return;
			}

			// Generar la expresión cron
			const newExpression = expressionGenerator();
			setGlobalValues(validValue);
			setValidVField(newExpression);
			setCurrentExpression(newExpression);
		};

		const expressionGenerator = () => {
			let expression = `0 ${validValue.hour.value.minuto} ${validValue.hour.value.hora}/${validValue.every.value} * * ?`;
			return expression;
		};

		return (
			<Box className="containerSchedule">
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<FormControl fullWidth>
							<InputLabel id="every-label">Cada</InputLabel>
							<Select
								labelId="every-label"
								value={validValue.every.value}
								onChange={onChangeEvery}
								label="Cada">
								{range.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.text}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={3}>
						<FormControl fullWidth>
							<InputLabel id="freq-label">Frecuencia</InputLabel>
							<Select
								labelId="freq-label"
								value={'hour'}
								onChange={chooseStructure}
								label="Frecuencia">
								{FREQ.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.text}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={1}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<Typography variant="body1">a las</Typography>
					</Grid>
					<Grid item xs={4}>
						<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
							<TimePicker
								label="Seleccionar hora"
								value={validValue.hour.date}
								onChange={onChangeTimer}
								minTime={minTime}
								maxTime={maxTime}
								renderInput={(params) => <TextField {...params} fullWidth />}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>

				{errorMessage && (
					<Typography color="error" variant="body2" sx={{mt: 2}}>
						{errorMessage}
					</Typography>
				)}

				<Box sx={{mt: 2}}>
					<Button variant="contained" color="primary" onClick={handleSave}>
						Guardar
					</Button>
				</Box>
			</Box>
		);
	};

	const DailyFrame = () => {
		const [validValue, setValidValue] = useState(
			GlobalValues && GlobalValues.name === 'daily'
				? GlobalValues
				: {
						name: 'daily',
						hour: {
							value: null,
							valid: false,
							date: null,
						},
				  }
		);

		const [errorMessage, setErrorMessage] = useState(null);

		const onChangeTimer = (newValue) => {
			if (newValue) {
				let fecha = newValue;
				let hora = fecha.getHours();
				let minuto = fecha.getMinutes();
				let hours = {hora, minuto};
				setValidValue({
					...validValue,
					hour: {
						value: hours,
						valid: true,
						date: fecha,
					},
				});
			}
		};

		const handleSave = () => {
			setErrorMessage(null);

			const selectedHour = validValue.hour.value.hora;
			if (selectedHour < 5 || selectedHour > 20) {
				setErrorMessage('La hora debe estar entre las 5 AM y las 8 PM.');
				return;
			}

			const newExpression = expressionGenerator();
			setGlobalValues(validValue);
			setValidVField(newExpression);
			setCurrentExpression(newExpression);
		};

		const expressionGenerator = () => {
			let expression = `0 ${validValue.hour.value.minuto} ${validValue.hour.value.hora} * * ?`;
			return expression;
		};

		const minTime = new Date(0, 0, 0, 5, 0); // 5 AM
		const maxTime = new Date(0, 0, 0, 20, 0); // 8 PM

		return (
			<Box className="containerSchedule">
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={1}>
						<Typography variant="body1">Cada</Typography>
					</Grid>
					<Grid item xs={4}>
						<FormControl fullWidth>
							<InputLabel id="freq-label">Frecuencia</InputLabel>
							<Select
								labelId="freq-label"
								value={'daily'}
								onChange={chooseStructure}
								label="Frecuencia">
								{FREQ.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.text}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={1}>
						<Typography variant="body1">a las</Typography>
					</Grid>
					<Grid item xs={4}>
						<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
							<TimePicker
								label="Seleccionar hora"
								value={validValue.hour.date}
								onChange={onChangeTimer}
								minTime={minTime}
								maxTime={maxTime}
								renderInput={(params) => <TextField {...params} fullWidth />}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>

				{errorMessage && (
					<Typography color="error" variant="body2" sx={{mt: 2}}>
						{errorMessage}
					</Typography>
				)}

				<Box sx={{mt: 2}}>
					<Button variant="contained" color="primary" onClick={handleSave}>
						Guardar
					</Button>
				</Box>
			</Box>
		);
	};

	const WeeklyFrame = () => {
		const [activeDays, setActiveDays] = useState(
		  GlobalValues && GlobalValues.name === "weekly"
			? GlobalValues.days.value
			: {
				sunday: false,
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false,
			  }
		);
	  
		const [validValue, setValidValue] = useState(
		  GlobalValues && GlobalValues.name === "weekly"
			? GlobalValues
			: {
				name: "weekly",
				weekInterval: {
				  value: 1,
				  valid: true,
				},
				hour: {
				  value: null,
				  valid: false,
				  date: null,
				},
				days: {
				  value: activeDays,
				  valid: false,
				},
			  }
		);
	  
		const [errorMessage, setErrorMessage] = useState(null);
	  
		const onChangeTimer = (newValue) => {
		  if (newValue) {
			let fecha = newValue;
			let hora = fecha.getHours();
			let minuto = fecha.getMinutes();
			let hours = { hora, minuto };
			setValidValue({
			  ...validValue,
			  hour: {
				value: hours,
				valid: true,
				date: fecha,
			  },
			});
		  }
		};
	  
		const handleButtonClick = (day) => {
		  setActiveDays((prevState) => {
			const updatedDays = {
			  ...prevState,
			  [day]: !prevState[day],
			};
			setValidValue((validValue) => ({
			  ...validValue,
			  days: {
				value: updatedDays,
				valid: true,
			  },
			}));
			return updatedDays;
		  });
		};
	  
		const handleWeekIntervalChange = (event) => {
		  setValidValue({
			...validValue,
			weekInterval: {
			  value: event.target.value,
			  valid: true,
			},
		  });
		};
	  
		const handleSave = () => {
		  setErrorMessage(null);
	  
		  const selectedHour = validValue.hour.value.hora;
		  const weekInterval = parseInt(validValue.weekInterval.value);
	  
		  if (selectedHour < 5 || selectedHour > 20) {
			setErrorMessage("La hora debe estar entre las 5 AM y las 8 PM.");
			return;
		  }
	  
		  let todosFalsos = Object.values(activeDays).every((valor) => valor === false);
		  if (todosFalsos) {
			setErrorMessage("Debe seleccionar al menos un día de la semana.");
			return;
		  }
	  
		  const newExpression = expressionGenerator();
		  setGlobalValues(validValue);
		  setValidVField(newExpression);
		  setCurrentExpression(newExpression);
		};
	  
		const expressionGenerator = () => {
		  let dayIndices = {
			sunday: "SUN",
			monday: "MON",
			tuesday: "TUE",
			wednesday: "WED",
			thursday: "THU",
			friday: "FRI",
			saturday: "SAT",
		  };
	  
		  let days = Object.entries(activeDays)
			.filter(([day, isActive]) => isActive)
			.map(([day]) => dayIndices[day])
			.join(",");
	  
		  let expression = `0 ${validValue.hour.value.minuto} ${validValue.hour.value.hora} ? * ${days}`;
		  return expression;
		};
	  
		const minTime = new Date(0, 0, 0, 5, 0); // 5 AM
		const maxTime = new Date(0, 0, 0, 20, 0); // 8 PM
	  
		// Opciones para el intervalo de semanas "cada N semanas"
		let weekIntervalOptions = [];
		for (let i = 1; i <= 4; i++) {
		  weekIntervalOptions.push({ text: `Cada ${i} semana(s)`, value: i });
		}
	  
		// Etiquetas de días en español
		const dayLabels = {
		  sunday: "Dom",
		  monday: "Lun",
		  tuesday: "Mar",
		  wednesday: "Mié",
		  thursday: "Jue",
		  friday: "Vie",
		  saturday: "Sáb",
		};
	  
		return (
		  <Box className="containerSchedule">
			<Grid container spacing={2}>
			  {/* Primera fila */}
			  <Grid item xs={12}>
				<Grid container spacing={2} alignItems="center">
				  <Grid item xs={3}>
					<FormControl fullWidth>
					  <InputLabel id="freq-label">Frecuencia</InputLabel>
					  <Select
						labelId="freq-label"
						value={"weekly"}
						onChange={chooseStructure}
						label="Frecuencia"
					  >
						{FREQ.map((item) => (
						  <MenuItem key={item.value} value={item.value}>
							{item.text}
						  </MenuItem>
						))}
					  </Select>
					</FormControl>
				  </Grid>
				  <Grid item xs={9}>
					<ButtonGroup variant="outlined">
					  {Object.entries(activeDays).map(([day, isActive]) => (
						<Button
						  key={day}
						  variant={isActive ? "contained" : "outlined"}
						  onClick={() => handleButtonClick(day)}
						>
						  {dayLabels[day]}
						</Button>
					  ))}
					</ButtonGroup>
				  </Grid>
				</Grid>
			  </Grid>
	  
			  {/* Segunda fila */}
			  <Grid item xs={12}>
				<Grid container spacing={2} alignItems="center">
				  <Grid item xs={2}>
					<Typography variant="body1">A las</Typography>
				  </Grid>
				  <Grid item xs={10}>
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
					  <TimePicker
						label="Seleccionar hora"
						value={validValue.hour.date}
						onChange={onChangeTimer}
						minTime={minTime}
						maxTime={maxTime}
						renderInput={(params) => <TextField {...params} fullWidth />}
					  />
					</LocalizationProvider>
				  </Grid>
				</Grid>
			  </Grid>
			</Grid>
	  
			{errorMessage && (
			  <Typography color="error" variant="body2" sx={{ mt: 2 }}>
				{errorMessage}
			  </Typography>
			)}
	  
			<Box sx={{ mt: 2 }}>
			  <Button variant="contained" color="primary" onClick={handleSave}>
				Guardar
			  </Button>
			</Box>
		  </Box>
		);
	  };
  

	const MonthlyFrame = () => {
		const [validValue, setValidValue] = useState(
			GlobalValues && GlobalValues.name === 'monthly'
				? GlobalValues
				: {
						name: 'monthly',
						day: {
							value: new Date().getDate() > 29 ? 29 : new Date().getDate(),
							valid: true,
						},
						hour: {
							value: null,
							valid: false,
							date: null,
						},
				  }
		);

		const [errorMessage, setErrorMessage] = useState(null);

		const dayChoosed = (event) => {
			setValidValue({
				...validValue,
				day: {
					value: event.target.value,
					valid: true,
				},
			});
		};

		const onChangeTimer = (newValue) => {
			if (newValue) {
				let fecha = newValue;
				let hora = fecha.getHours();
				let minuto = fecha.getMinutes();
				let hours = {hora, minuto};
				setValidValue({
					...validValue,
					hour: {
						value: hours,
						valid: true,
						date: fecha,
					},
				});
			}
		};

		const handleSave = () => {
			setErrorMessage(null);

			const selectedHour = validValue.hour.value.hora;
			if (selectedHour < 5 || selectedHour > 20) {
				setErrorMessage('La hora debe estar entre las 5 AM y las 8 PM.');
				return;
			}

			const newExpression = expressionGenerator();
			setGlobalValues(validValue);
			setValidVField(newExpression);
			setCurrentExpression(newExpression);
		};

		const expressionGenerator = () => {
			let expression = `0 ${validValue.hour.value.minuto} ${validValue.hour.value.hora} ${validValue.day.value} * ?`;
			return expression;
		};

		const daysArray = [];
		for (let i = 1; i <= 29; i++) {
			daysArray.push(i);
		}

		const minTime = new Date(0, 0, 0, 5, 0); 
		const maxTime = new Date(0, 0, 0, 20, 0); 

		return (
			<Box className="containerSchedule">
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={1}>
						<Typography variant="body1">Cada</Typography>
					</Grid>
					<Grid item xs={4}>
						<FormControl fullWidth>
							<InputLabel id="freq-label">Frecuencia</InputLabel>
							<Select
								labelId="freq-label"
								value={'monthly'}
								onChange={chooseStructure}
								label="Frecuencia">
								{FREQ.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.text}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={1}>
						<Typography variant="body1">el día</Typography>
					</Grid>
					<Grid item xs={2}>
						<FormControl fullWidth>
							<InputLabel id="day-label">Día</InputLabel>
							<Select
								labelId="day-label"
								value={validValue.day.value}
								onChange={dayChoosed}
								label="Día">
								{daysArray.map((day) => (
									<MenuItem key={day} value={day}>
										{day}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={1}>
						<Typography variant="body1">a las</Typography>
					</Grid>
					<Grid item xs={3}>
						<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
							<TimePicker
								label="Seleccionar hora"
								value={validValue.hour.date}
								onChange={onChangeTimer}
								minTime={minTime}
								maxTime={maxTime}
								renderInput={(params) => <TextField {...params} fullWidth />}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>

				{errorMessage && (
					<Typography color="error" variant="body2" sx={{mt: 2}}>
						{errorMessage}
					</Typography>
				)}

				<Box sx={{mt: 2}}>
					<Button variant="contained" color="primary" onClick={handleSave}>
						Guardar
					</Button>
				</Box>
			</Box>
		);
	};

	const handleReset = (e) => {
		e.preventDefault();
		onChange(null); // Pasar null directamente
		setMessageTraslate(null);
		setGlobalValues(null);
		setSelectFrame('daily');
	};

	return (
		<Box sx={{width: '700px'}}>
			<Typography variant="h6">{'Programador de Cron'}</Typography>
			<br />
			<Box className="containerSchedule">
				{messageTraslate ? (
					<Box className="message-expressionCron">
						<Typography variant="h5">{messageTraslate}</Typography>
						<Button
							onClick={handleReset}
							size="large"
							className="button-expressionCron">
							<RotateLeftIcon />
						</Button>
					</Box>
				) : (
					<Frame selectedFrame={selectFrame} />
				)}
			</Box>
		</Box>
	);
};

export default CronScheduler;


