import { useState } from 'react';
import {Field} from '@progress/kendo-react-form';
import {
	FormRadioGroup,
	FormTextArea,
	FormDropDownList,
    FormInput,
} from '../../components/RequestForm/form-components';
import useAxios from '../../hooks/UseAxios';
import { Loader } from '@progress/kendo-react-indicators';
import { AddWhoWillSee } from '../../components/RequestForm/AddWhoWillSee';
import { RadioButtonValidator, WhoWillSeeValidator, requiredValidator } from '../../components/RequestForm/Validators';
import { incidenceTypes, normalize, yes_no } from '../../components/RequestForm/data';
import { baseURL } from '../../../Config';
import { useSelector } from 'react-redux';
import { selectCredencialesArea, selectCredencialesRole } from '../../../redux/credencialesReducer';
import CustomUploadField from '../../components/PublicacionReportes/CustomUploadField/CustomUploadField';
import { IsAuthorized } from '../../../components/auth/IsAuthorized';

export const Step2Requerimiento = ()=>{
	const [chossedSubArea, setChossedSubArea] = useState([])
	const rol=useSelector(selectCredencialesRole);
	const area=useSelector(selectCredencialesArea);
	const isAuth=IsAuthorized('UploadFiles',area,rol)
	const updatedIncidenceTypes = isAuth
    ? [
        ...incidenceTypes,
        { label: 'Cargar archivo', value: 'Cargar archivo' },
      ]
    : incidenceTypes;
	const [folderName, setFolderName] = useState("");
	const [isChoosingNewFile, setIsChoosingNewFile] = useState(false)
	const [isValidFolderName, setisValidFolderName] = useState(false)
	const{data, isLoading, error}=useAxios(`${baseURL}/api/v1/helpDesk/AreaAndSubAreas`)
	const handleSubArea=(event) => {
		
	    setChossedSubArea(data[event.value])
	}
	const handleNewfile = (event) => {
		if(event.value === "Cargar archivo"){
			setIsChoosingNewFile(true)
		}else{
			setIsChoosingNewFile(false)
		}
	}

	const handleFolderNameChange = (event) => {
		if(event.target.value.length>3){
			let value = event.target.value;
			value=normalize(value)
			setFolderName(value);
			setisValidFolderName(true)
		}else{
			setisValidFolderName(false)
			setFolderName("");
		}
			
	};
	return(
		<div>
		<Field
			key={'requestDate'}
			id={'requestDate'}
			name={'requestDate'}
			label={'Fecha de registro:'}
			component={FormInput}
			disabled={true}
		/>
		<Field
			key={'requestNeed'}
			id={'requestNeed'}
			name={'requestNeed'}
			label={'Tipo de Requerimiento'}
			layout={'horizontal'}			
			component={FormRadioGroup}
			data={updatedIncidenceTypes}
			onChange={handleNewfile}
			validator={requiredValidator}
		/>
		  <>
    {isChoosingNewFile && (
      <div style={{ backgroundColor: "#feffe5" }}>
        <span className={"k-form-separator"} />
        <div>
          <h4 style={{ marginTop: "10px" }}>Upload File (Optional)</h4>
        </div>
        <div>
          <Field
            key={"folderName"}
            id={"folderName"}
            name={"folderName"}
            optional={!isValidFolderName}
            label={"Nombre de la carpeta :"}
            placeholder={
              'Ingrese el nombre de la carpeta que desea subir el archivo "si aplica"'
            }
            component={FormInput}
            value={folderName} 
            onChange={handleFolderNameChange} 
          />
        </div>
        {isValidFolderName && (
          <Field
            key={"File"}
            id={"File"}
            name={"File"}
            label={"Subir archivo:"}
            optional={!isValidFolderName}
            hint={"Upload your file here"}
            folderName={folderName} // Valor pasado al componente
            restrictions={{
              allowedExtensions: [".xlsx", ".csv"],
            }}
            component={CustomUploadField}
            validator={requiredValidator}
          />
        )}
        <br />
        <span className={"k-form-separator"} />
      </div>
    )}
  </>
		<Field
			key={'requestProduct'}
			id={'requestProduct'}
			name={'requestProduct'}
			label={'¿Cuál es tu necesidad?'}
			placeholder={"Describa tu necesidad"}
			hint={'Escriba una breve descripción'}
			component={FormInput}
			validator={requiredValidator}
		/>
		<Field
			key={'requestTool'}
			id={'requestTool'}
			name={'requestTool'}
			label={'¿En qué herramienta la necesitas?'}
			placeholder={"Nombre de la herramienta ejemplo: SQL query, Data Factory, etc"}
			hint={'Nombre de la herramienta'}
			component={FormInput}
			validator={requiredValidator}
		/>
		<Field
			key={'descriptionTask'}
			id={'descriptionTask'}
			name={'descriptionTask'}
			label={'¿Qué esperas de la entrega?'}
			placeholder={"Ingresa una descripción de tu requerimiento"}
			component={FormTextArea}
			validator={requiredValidator}
		/>
		
        <Field
			key={'requestUse'}
			id={'requestUse'}
			name={'requestUse'}
			label={'¿Qué uso le daras?'}
			placeholder={"Ingresa una descripción del uso que le daras a la información"}
			component={FormTextArea}
			validator={requiredValidator}
		/>
		<Field
			key={'requestViewInf'}
			id={'requestViewInf'}
			name={'requestViewInf'}
			label={'Ingresa el nombre de las personas que veran la información'}
			component={AddWhoWillSee}
			validator={WhoWillSeeValidator}
		/>
		<Field
			key={'requestAutomatization'}
			id={'requestAutomatization'}
			name={'requestAutomatization'}
			label={'¿Necesitas que este automatizada?'}
			layout={'horizontal'}			
			component={FormRadioGroup}
			data={yes_no}
			validator={RadioButtonValidator}
		/>
		<Field
			key={'areas'}
			id={'areas'}
			name={'areas'}
			label={'¿A qué área corresponden los datos?'}
			component={FormDropDownList}
			data={Object.keys(data)}
			onChange={handleSubArea}
			validator={requiredValidator}
		/>
		{isLoading && <Loader size="medium" />}
		<Field
			key={'subarea'}
			id={'subarea'}
			name={'subarea'}
			label={'¿Cuál es la subarea?'}
			component={FormDropDownList}
			data={chossedSubArea}
			validator={requiredValidator}
		/>
		{error && <h3>{error}</h3>}

		
		
	</div>
	);
}
	

