import * as React from 'react';
import { AutoComplete, DropDownList } from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import { filterBy } from "@progress/kendo-data-query";
import { DatePicker, DateTimePicker } from "@progress/kendo-react-dateinputs";
import {ActiveCell, TopicsDateCell} from './GridCells';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Badge } from '@progress/kendo-react-indicators';
import { baseURL } from '../../Config';

export const TopicsDrowpdown = props => {
  const [activeTopics, setActiveTopics] = React.useState([]);
  const [isloading,setLoading] = React.useState(true);
  React.useEffect(() => {
    const loadGet = async () => {
      const response = await axios.get(`${baseURL}/api/v1/TNB/TrainingDetails/Topics`);
      const test=response.data
      setActiveTopics(test)
      setLoading(false);
    }
    loadGet();
},[]);

  const handleChange = e => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.nombreActividad
      });
    }
  };
  const {
    dataItem
  } = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];

  return <td>
        {dataItem.inEdit ? 
          <DropDownList style={{
            width: "100%"
          }} 
            onChange={handleChange}
            value={activeTopics.find((c) => c.nombreActividad === dataValue)} 
            data={activeTopics} 
            textField="nombreActividad" /> 
          : 
          dataValue.toString()}
      </td>;
};

export const TrainerDrowpdown = props => {
  const [activeTrainers, setActiveTrainers] = React.useState([]);
  const [isloadingTrainers,setLoadingTrainers] = React.useState(true);
  React.useEffect(() => {
    const loadGet = async () => {
      const responseTrainers = await axios.get(`${baseURL}/api/v1/TNB/Training/Trainers`);
      const testTrainers=responseTrainers.data
      setActiveTrainers(testTrainers)
      setLoadingTrainers(false);
    }
    loadGet();
},[]);

  const handleChange = e => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.Nombre
      });
    }
  };
  const {
    dataItem
  } = props;
  const field = props.field || '';
  const dataValue = (dataItem[field] === null || dataItem[field] === undefined) ? '' : dataItem[field];

  return <td>
        {dataItem.inEdit ? 
          <DropDownList style={{
            width: "100%"
          }} 
            onChange={handleChange}
            value={activeTrainers.find((d) => d.Nombre === dataValue)} 
            data={activeTrainers} 
            textField="Nombre" /> 
          : 
          dataValue.toString()}
      </td>;
};

export const TypeDrowpdown = props => {
  const [activeType, setActiveType] = React.useState([]);
  const [isloadingType,setLoadingType] = React.useState(true);
  React.useEffect(() => {
    const loadGet = async () => {
      const responseType = await axios.get(`${baseURL}/api/v1/TNB/Training/Types`);
      const testType=responseType.data
      setActiveType(testType)
      setLoadingType(false);
    }
    loadGet();
},[]);

  const handleChange = e => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.tipo
      });
    }
  };
  const {
    dataItem
  } = props;
  const field = props.field || '';
  const dataValue = (dataItem[field] === null || dataItem[field] === undefined) ? '' : dataItem[field];

  return <td>
        {dataItem.inEdit ? 
          <DropDownList style={{
            width: "100%"
          }} 
            onChange={handleChange}
            value={activeType.find((f) => f.tipo === dataValue)} 
            data={activeType} textField="tipo" /> 
          : 
          dataValue.toString()}
      </td>;
};

export const DateTopicsEditor = props => {

let today = new Date();
let newDate=today.toDateString();
  const onChange = e => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value
      });
    }
  };
  const {
    dataItem
  } = props;
  const field = props.field || '';
  const dataValue = (dataItem[field] === undefined || dataItem[field] === null) ? newDate : dataItem[field];
  const showDate=new Date(dataValue).toLocaleDateString();
  return <td>
    {dataItem.inEdit ? 
          <DatePicker
            name={field}
            placeholder={""}
            format="dd/MM/yyyy"
            onChange={onChange}
            textField="fecha"
          />
          : 
          showDate}
      </td>;
};


export const StatusEditor = props => {
  let view
    const onChange = e => {
      if (props.onChange) {
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: props.field,
          syntheticEvent: e.syntheticEvent,
          value: e.target.value
        });
      }
    };
    const {
      dataItem
    } = props;
    const field = props.field || '';
    const dataValue = (dataItem[field] === undefined || dataItem[field] === null) ? '' : dataItem[field];
    const badgeStyle = {
      position: 'relative',
      display: 'inline',
      fontSize: '12px'
  };

    {
      dataValue === true ?
          view=<Badge themeColor={'success'} shape={'rounded'} style={badgeStyle} fill={'outline'}>Activo</Badge> :
          view=<Badge themeColor={'light'} shape={'rounded'} style={badgeStyle} fill={'outline'}>Inactivo</Badge>
  }
    return <td style={{textAlign: 'center'}}>
      {dataItem.inEdit ? 
            <Checkbox
              defaultValue={dataValue}
              onChange={onChange}
              textField="estado"
            />
            : 
            <>{view}</>
            }
        </td>;
  };
  






