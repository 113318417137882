import {
	displayDate,
	parseAdjust,
	customModelFields,
	baseData,
} from './DataFunction.js';

import {Scheduler, MonthView, DayView} from '@progress/kendo-react-scheduler';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Loader} from '@progress/kendo-react-indicators';

import {CustomFormOperation} from './CustomFormOperation.js';
import CustomSnackbar from '../Notification/SnackBar';
import {
	changeIsLoadingDayOperation,
	selectactivitiesDayOperation,
	selectisLoadingDayOperation,
	thunkGetMonthOperation,
} from '../../redux/dayOperationReducer.js';
import axios from 'axios';
import { baseURL } from '../../Config.js';
import { IsAuthorized } from '../auth/IsAuthorized.js';

const resources = [
	{
		name: 'Colaborador de Siigo',
		data: [
			{
				text: 'Data Analytics',
				value: '1',
				color: '#5392E4',
			},
			{
				text: 'Data Engineer',
				value: '2',
				color: '#39A934',
			},
			{
				text: 'Festivo',
				value: '3',
				color: '#999999',
			},
		],
		field: 'Área',
		valueField: 'value',
		textField: 'text',
		colorField: 'color',
	},
];

const DayOperation = ({deffault='month'}) => {
	let activities = useSelector(selectactivitiesDayOperation)?.map(
		(dataItem) => ({
			...dataItem,
			Start: parseAdjust(dataItem.Start),
			End: parseAdjust(dataItem.End),
		})
	);
	
	const isloadingActivities = useSelector(selectisLoadingDayOperation);
	const dispatch = useDispatch();
	const role = sessionStorage.getItem('role');
	const area = sessionStorage.getItem('area');
	const isAllowedChatbotAlice=IsAuthorized('Operation',area,role);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [messageSnackBar, setMessageSnackBar] = useState(null);
	const [typeSnackBar, setTypeSnackBar] = useState('success');

	const handleOpenSnackbar = () => {
		setSnackbarOpen(true);
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	useEffect(() => {
		dispatch(thunkGetMonthOperation());
	}, [dispatch]);

	const handleDataChange = async ({created, updated, deleted}) => {
		
		dispatch(changeIsLoadingDayOperation(true));
		let message = '';
		if (isAllowedChatbotAlice) {
			try {
				if (created[0] !== undefined) {
					message = await axios.post(`${baseURL}/api/v1/Operation/month`, created[0]);
				} else if (deleted[0] !== undefined) {
					message = await axios.delete(`${baseURL}/api/v1/Operation/month`, {data: deleted[0]});
				} else if (updated[0] !== undefined) {
					message = await axios.put(`${baseURL}/api/v1/Operation/month`, updated[0]);
				}

				setTypeSnackBar('success');
				setMessageSnackBar(message.data.Message);
				handleOpenSnackbar();
				// Despachar después de las operaciones
				dispatch(thunkGetMonthOperation());
			} catch (error) {
				console.error('Error en operación CRUD', error);
				setTypeSnackBar('error');
				setMessageSnackBar('Hubo un error, no pudimos completar la petición');
				handleOpenSnackbar();
				dispatch(thunkGetMonthOperation());
			}
		} else {
			setTypeSnackBar('info');
			setMessageSnackBar('Ni lo intentes, ya le tocó ese día Operación');
			handleOpenSnackbar();
		}
		dispatch(changeIsLoadingDayOperation(false));
	};

	if (isloadingActivities) {
		return (
			<div>
				<Loader
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
					size="large"
					type={'converging-spinner'}
				/>
				<span>
					<p>Cargando Actividades...</p>
				</span>
			</div>
		);
	}

	return (
		<>
			
			<div style={{display: 'flex', alignItems: 'center'}}>
				<div
					style={{display: 'flex', alignItems: 'center', marginRight: '20px'}}>
					<p style={{margin: '0 10px 0 0'}}>Data Engineer</p>
					<div
						style={{
							width: '20px',
							height: '20px',
							backgroundColor: '#5392E4',
						}}></div>
				</div>
				<div style={{display: 'flex', alignItems: 'center'}}>
					<p style={{margin: '0 10px 0 0'}}>Data Analytics</p>
					<div
						style={{
							width: '20px',
							height: '20px',
							backgroundColor: '#39A934',
						}}></div>
				</div>
			</div>
			<Scheduler
				data={activities}
				timezone={'America/Bogota'}
				resources={resources}
				onDataChange={handleDataChange}
				height={820}
				editable={true}
				defaultDate={displayDate}
				defaultView={deffault}
				modelFields={customModelFields}
				form={CustomFormOperation}>
				<MonthView />
				<DayView
					title="Day-View"
					numberOfDays={1}
					slotDuration={60}
					slotDivisions={2}
					startTime={'07:00'}
					endTime={'19:00'}
					workDayStart={'07:00'}
					workDayEnd={'19:00'}
				/>
			</Scheduler>
			<CustomSnackbar
				open={snackbarOpen}
				handleClose={handleCloseSnackbar}
				message={messageSnackBar}
				severity={typeSnackBar}
			/>
		</>
	);
};

export default DayOperation;
