import React, { useEffect, useState } from 'react';
import {
	PanelBar,
	PanelBarItem,
} from '@progress/kendo-react-layout';
import './styles.css';
import CardShowRowUpd from '../firstSlice/CardShowRowUp';
import { useDispatch, useSelector} from 'react-redux';
import {
	selecthistory,
	setMenuWidth,
	setOpenSeconDisplay,
	setSubMenu,
} from '../../../../../redux/ControlCenterReducer';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {GoCopy} from 'react-icons/go';
import { Slide } from '@progress/kendo-react-animation';
import { SecondaryDisplay } from '../../SecondaryDisplay/main';
import { SecondSlice } from '../SecondSlice/SecondSlice';
import ThirdSlice from '../ThirdSlice/ThirdSlice';
import FourthSlice from '../FourthSlice/FourthSlice';
import { ExtractExecutionTimes } from './Methods';
import { KPIs } from '../fifthSlice/KPIs';
import { Button } from '@progress/kendo-react-buttons';
import { MdDeleteOutline, MdOutlineFileDownload } from "react-icons/md";
import DownloadReport, { handleDownloadPBI } from './handleDownloadPBI';
import { DeleteReport } from './deleteReport';
import CustomSnackbar from '../../../../../components/Notification/SnackBar';
import GrantPermissions from '../sixthSlice/Permissions';
export const Slice = ({
	isOpenSeconDisplay,
	dataReportFabric,
	datasetId,
	expandChange,
}) => {
	const dispatch = useDispatch();
	const [nameBtn, setnameBtn] = useState('Updates')
	const historyData = useSelector(selecthistory);
	const {maxExecutionTimeInSeconds,minExecutionTimeInSeconds,avgExecutionTimeInSeconds,successPercentage}=ExtractExecutionTimes(historyData);
	const [isDownload,setIsDownload]=useState(false)
	const [toast, setToast] = useState({
		snackbarOpen: false,
		message: '',
		type: '',
	});
	const filteredDataReportFabric = { ...dataReportFabric };
	const reportId=filteredDataReportFabric.reportId;
	delete filteredDataReportFabric.reportId;

	const handleSelect = (event) => {
		const selectedTitle = event.target.props.title;
		
		if (selectedTitle !== 'Last Update'||selectedTitle !== 'Performance de Power BI'||selectedTitle !== 'Performance DataBricks') {
			dispatch(setSubMenu(selectedTitle));
			dispatch(setOpenSeconDisplay(true));
			dispatch(setMenuWidth(1300));
		} 
	};
	const handleCloseSnackbar = () => {
		setToast({...toast, snackbarOpen: false});
	};

	// let Menuwidth = useSelector(selectMenuWidth);
	const children = isOpenSeconDisplay ? (
		<div className="childrenControlCenter1">
			<SecondaryDisplay setToast={setToast}expandChange={expandChange} datasetId={datasetId}/>
		</div>
	) : null;
	return (
		<div>
			<div
				className={
					isOpenSeconDisplay
						? 'containerAccordeon-expanded'
						: 'containerAccordeon'
				}>
				{Object.entries(filteredDataReportFabric).map(([key, value]) => (
					<div key={key} style={{display: 'flex', alignItems: 'center'}}>
						<span style={{fontWeight: 'bold', marginRight: '8px'}}>{key}:</span>
						<span>{value}</span>
						<CopyToClipboard text={value}>
							<GoCopy
								style={{marginLeft: '8px', cursor: 'pointer'}}
								title="Copiar al portapapeles"
							/>
						</CopyToClipboard>
					</div>
				))}
				<CustomSnackbar
					open={toast.snackbarOpen}
					handleClose={handleCloseSnackbar}
					message={toast.message}
					severity={toast.type}
				/>				
				<div style={{'display':'flex'}}>
				<DeleteReport setToast={setToast} name={filteredDataReportFabric["Nombre embedded "]}reportId={reportId} datasetId={datasetId}/>	
				<DownloadReport reportId={reportId} name={filteredDataReportFabric["Nombre embedded "]} setToast={setToast}/>
				</div>
			</div>
			
			<div
				className={`ControlCenterGrid`}>
				{<Slide transitionEnterDuration={2000} transitionExitDuration={2000} direction={'down'}>{children}</Slide>}				
				<div className="childrenControlCenter2">
					<PanelBar onSelect={handleSelect} style={{width: `${isOpenSeconDisplay?'485px':'685px'}`}}>
						<PanelBarItem expanded={false} title={'Last Updated'}>
							<CardShowRowUpd />
						</PanelBarItem>
						<PanelBarItem expanded={false} title={'Performance de Power BI'}>
							<SecondSlice max={maxExecutionTimeInSeconds}min={minExecutionTimeInSeconds}avg={avgExecutionTimeInSeconds}/>
							<ThirdSlice successPercentage={successPercentage}/>
						</PanelBarItem>
						<PanelBarItem expanded={false} title={'Performance DataBricks'}>
							<FourthSlice />
						</PanelBarItem>
						{isOpenSeconDisplay&&<PanelBarItem expanded={false} title={nameBtn} >							
							</PanelBarItem>}

						<PanelBarItem expanded={false} title={'Metadata'}>
							<PanelBarItem expanded={false} title={'Titulo del Reporte'}>
							
							</PanelBarItem>
							<PanelBarItem expanded={false} title={'Permisos del Reporte'}>
							
							</PanelBarItem>
							<PanelBarItem expanded={false} title={'Linaje'}>
							
							</PanelBarItem>
							<PanelBarItem expanded={false} title={'Horarios de Ejecución'}>
							
							</PanelBarItem>
							<PanelBarItem expanded={false} title={'KPIs'}>
							
							</PanelBarItem>
							<PanelBarItem expanded={false} title={'Descripción'}>
							
							</PanelBarItem>
							<PanelBarItem expanded={false} title={'Tipo de reporte'}>
							
							</PanelBarItem>
						</PanelBarItem>
					</PanelBar>
				</div>
			</div>
		</div>
	);
};
