import './NotificationCard.css'; // Archivo CSS separado
import { IoCloudUpload } from "react-icons/io5";
import { MdDashboardCustomize } from "react-icons/md";
import { IoInformationSharp } from "react-icons/io5";
import { doLink } from '../../pages/viewsHome/layout/header';
import { useNavigate } from 'react-router-dom';
function NotificationCard({notification,notifiPushed}) {
  const {title, message, type_not,path_report,is_read} = notification;
  const navigate = useNavigate();
  const type={
    report: {icon: <IoCloudUpload />, color: '#DBFFCE'},
    indicator: {icon: <MdDashboardCustomize />, color: '#D7CEFF'},
    news: {icon: <IoInformationSharp />, color: '#CEE9FF'}
  }
  let link = doLink(path_report);
  const handleClick = () => {  
    notifiPushed(notification._id);
    navigate(`/dashboardpage/${link}`);
  }
  return (
    <div className="notification-card" style={{ backgroundColor: is_read ? '#ffffff' : type[type_not].color }}>
      <div className="notification-card-card-header">
        <div className='notification-card-icon-circle' style={{'backgroundColor':type[type_not].color}}>
          {type[type_not].icon}
        </div>
        <span className="notification-card-title">{title}</span>
        <div className="notification-card-close-button">✕</div>
      </div>
      <div className="notification-card-card-body">
        <p className="notification-card-message">
          {message}<br />
        </p>
      </div>
      <div style={{width:'100%', display:'flex',justifyContent: 'center'}}><div className="notification-card-action-button" onClick={() => {handleClick()}}>ver más</div></div>
      
    </div>
  );
}

export default NotificationCard;
