import Box from '@mui/material/Box';
import NotificationCard from './NotificationCard';
import {SwipeableDrawer} from '@mui/material';
import { useDispatch } from 'react-redux';
import { setNotifications, updateStateNotification } from '../../redux/notificationsReducer';

export default function NotificationDrawer({notifications, setOpen, open}) {
	const dispatch=useDispatch();
	const toggleDrawer = (open) => (event) => {
		if (event.target.classList.contains('notification-card-close-button')) {
			event.stopPropagation();
			return;
		}
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setOpen(open);
	};
	const notifiPushed=(id)=>{
			const updatedNotifications = notifications.map(notification => 
				notification._id === id ? {...notification, is_read: true} : notification
			);
			dispatch(setNotifications(updatedNotifications));
			dispatch(updateStateNotification([id]));

	}
	const DrawerList = (
		<Box sx={{width: 320}} role="presentation" onClick={toggleDrawer(false)}>
			{Array.isArray(notifications) && notifications.length > 0 ? (
				notifications.map((notification) => (
					<NotificationCard
						key={notification._id}
						notification={notification}
						notifiPushed={notifiPushed}
					/>
				))
			) : (
				<p>You have no notifications</p>
			)}
		</Box>
	);

	return (
		<div>
			<SwipeableDrawer
				open={open}
				anchor="right"
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}>
				{DrawerList}
			</SwipeableDrawer>
		</div>
	);
}
