import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { baseURL } from '../Config';

export const NotificationsSlice = createSlice({
  name: 'Notifications',
  initialState: {
    Notifications: [],
    isLoadingNotifications: false,
  },
  reducers: {
    setNotifications: (state, action) => {
      state.Notifications = action.payload;
    },
    setIsLoadingNotifications: (state, action) => {
      state.isLoadingNotifications = action.payload;
    },

  },
})

export const getPersonalNotificacitions=(email=undefined)=>{
    return async (dispatch,getState)=>{
        dispatch(setIsLoadingNotifications(true));
        const response = await axios.get(
          `${baseURL}/api/v1/notifications/getPersonalNotificacitions?email=${email}`
        );
        const test = response.data;
        dispatch(setNotifications(test))
        dispatch(setIsLoadingNotifications(false));
    }
}
export const updateStateNotification=(ids)=>{
  return async (dispatch,getState)=>{
      dispatch(setIsLoadingNotifications(true));

      const response = await axios.put(
        `${baseURL}/api/v1/notifications/updateStateNotification`,
        { ids }
      )
      dispatch(setIsLoadingNotifications(false));
  }
}

export const { setNotifications,setIsLoadingNotifications } = NotificationsSlice.actions

export const selectNotifications = state => state.Notifications.Notifications
export const selectisLoadingNotifications = state => state.Notifications.isLoadingNotifications



export default NotificationsSlice.reducer