
import { MyCommandCellParticipantes } from "./topicsCommandCell";
import { Grid, GridColumn as Column, GridColumnMenuFilter, GridColumnMenuSort, GridToolbar } from "@progress/kendo-react-grid";
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import {ActiveCell, Desertor, DesertorParticipo, Participo} from './GridCells';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Button } from '@progress/kendo-react-buttons'
import {  TopicsParticipantsDrowpdown, TopicsParticipantsStatusDrowpdown } from "./participantsFields";
import { topicsContext } from "./trainingTopicsTable";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  selectDetalleCapacitacion, setDetalleCapacitacion } from "../../redux/TNBSlice";
import { fileExcelIcon } from "@progress/kendo-svg-icons";
import { baseURL } from "../../Config";
const editField = "inEdit";

export const myContext=createContext(null)

export const ColumnMenu = (props) => {
  return (
      <div>
          <GridColumnMenuSort {...props} />
          <GridColumnMenuFilter {...props} />
      </div>
  );
}//componente funcional que ofrece opciones para ordenar y filtrar una columna de la tabla





export const TrainingParticipantsTable = (props) => {
  const [dataDetailsParticipants, setDataDetailsParticipants] = useState([]);//Almacena los datos de los participantes.
  const [isloading,setLoading] = useState(true);
  const [take, setTake] = useState();
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState([]);
  const [group, setGroup] = useState([]);
  const [filter, setFilter] = useState(null);
  
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const dispatch=useDispatch()

  // const myValue={trainingName:props.value, updateTrigger:trigger}
  const [myValue, setMyValue] = useState(props.value);
  const capacitacion=useSelector(selectDetalleCapacitacion);
  const inactiveParticipantsTraining = capacitacion.estado; 
  const _export = useRef(null);
  //acá se carga la info de los participantes
  useEffect(() => {
    const loadGet = async () => {
      
      try {
        setDataDetailsParticipants(capacitacion.participantes)

        setLoading(false);
      } catch (error) {
        setDataDetailsParticipants(undefined)
      }
      
    }
    loadGet();
},[capacitacion]);

const dataState = {
  take,
  skip,
  sort,
  group,
  filter
};

const onDataStateChange = useCallback(
  (event) => {
      setTake(event.dataState.take);
      setSkip(event.dataState.skip);
      setSort(event.dataState.sort);
      setGroup(event.dataState.group);
      setFilter(event.dataState.filter);
  },
  [setTake, setSkip, setSort, setGroup]
);


  // modify the data in the store, db etc
  const remove = dataItem => {

    axios.post(`${baseURL}/api/v1/TNB/TrainingDetails/Participants/DeleteOne?id=${props.value}`, dataItem).then((response)=>{
      const resultRemove=response.data
      setDataDetailsParticipants(resultRemove.participantes)
      dispatch(setDetalleCapacitacion(resultRemove))
    })
  };
  const add = dataItem => {
    dataItem.inEdit = false;
    
    axios.post(`${baseURL}/api/v1/TNB/TrainingDetails/Participants/InsertOne?id=${props.value}`, dataItem)
    .then((response)=>{
      const resultAdd=response.data
      setDataDetailsParticipants(resultAdd.participantes)
      dispatch(setDetalleCapacitacion(resultAdd))
    });
  };
  const update = dataItem => {
    dataItem.inEdit = false;
    axios.post(`${baseURL}/api/v1/TNB/TrainingDetails/Participants/UpdateOne?id=${props.value}`, dataItem)
    .then((response)=>{
      const resultAdd=response.data
      setDataDetailsParticipants(resultAdd.participantes)
      dispatch(setDetalleCapacitacion(resultAdd))
    })
  };

  // Local state operations
  //Elimina un participante del estado local (sin llamar a la API).
  const discard = () => {
    const newData = [...dataDetailsParticipants];
    newData.splice(0, 1);
    setDataDetailsParticipants(newData);
  };
  // Cancela la edición de un participante.
  const cancel = dataItem => {
    setDataDetailsParticipants(dataDetailsParticipants.map(item => item.idParticipante === dataItem.idParticipante ? {
        ...item,
        inEdit: false
      } : item));
  };
  //Pone un participante en modo de edición.
  const enterEdit = dataItem => {
    setDataDetailsParticipants(dataDetailsParticipants.map(item => item.idParticipante === dataItem.idParticipante ? {
      ...item,
      inEdit: true
    } : item));
  };
  // Maneja cambios en los datos de un participante (por ejemplo, al editar un campo).
  const itemChange = event => {
    const newData = dataDetailsParticipants.map(item => item.idParticipante === event.dataItem.idParticipante ? {
      ...item,
      [event.field || '']: event.value
    } : item);
    setDataDetailsParticipants(newData);
  };
  // Agrega un nuevo participante al estado local.
  const addNew = () => {
    const newDataItemParticipants = {
      inEdit: true,
      Discontinued: false
    };
    setDataDetailsParticipants([newDataItemParticipants, ...dataDetailsParticipants]);
  };


  const CommandCell = props => <MyCommandCellParticipantes {...props} edit={enterEdit} remove={remove} add={add} discard={discard} update={update} cancel={cancel} editField={editField} />;

  const processedData = process(dataDetailsParticipants, dataState);
  

  if (isloading) {
    return <div className='desktop-view-SDR'>
                <div id='reportContainerSDR'>
                <div className='centerDS'><CircularProgress/></div>
                </div>
            </div>
} else if (dataDetailsParticipants === undefined) { return <h2>No hay Datos, algo pasó con la capacitación</h2>} else
  return (    
  <>
  <myContext.Provider value={myValue}>
    <ExcelExport data={dataDetailsParticipants} ref={_export}>
    <Grid
        style={{
          height: "100%",
        }}

        data={processedData} onItemChange={itemChange} onDataStateChange={onDataStateChange} resizable={true} editField={editField} 
      >
      <GridToolbar>
              <Button title="Add new" size={"small"} rounded={"medium"} themeColor={"base"}  onClick={addNew} disabled={!inactiveParticipantsTraining}>
                Agregar
              </Button>
              <Button
                      svgIcon={fileExcelIcon}
                      onClick={excelExport}
                      size={"small"} rounded={"medium"} themeColor={"success"} fillMode={"outline"} 
                  ></Button>
        </GridToolbar>
        <Column field={"idParticipante"} title={"ID"} width="60%" editable={false}  columnMenu={ColumnMenu} style={{fontSize:"10px"}} />
        <Column field={"documentoParticipante"} title={"Document"}  columnMenu={ColumnMenu} editor="text" width="120%" style={{fontSize:"10px"}}/>
        <Column field={"nombreParticipante"} title={"Nombre"} editable={false} columnMenu={ColumnMenu} />
        <Column field={"area"} title={"Área"} editable={false} width="80px" columnMenu={ColumnMenu}/>
        <Column field={"lider"} title={"Líder"} editable={false} columnMenu={ColumnMenu}/>
        <Column field={"nombreActividad"} title={"Tema"}  columnMenu={ColumnMenu} cell={TopicsParticipantsDrowpdown} />
        <Column field={"nota"} title={"Nota"} editor="numeric" width="99px"columnMenu={ColumnMenu} />
        <Column field={"estado"} title={"Estado"} columnMenu={ColumnMenu} cell={TopicsParticipantsStatusDrowpdown} width="110%"/>
        {inactiveParticipantsTraining===true?<Column cell={CommandCell} title={"Acciones"} width="150%" />:null }
      </Grid>
      </ExcelExport>
    </myContext.Provider></>
  )
  
};
