import { useEffect,useState} from 'react';
import {Field} from '@progress/kendo-react-form';
import {
	RadioButtonValidator,
	RouteValidator,
	WhoWillSeeValidator,
	reportValidator,
	requiredValidator,
	userNameValidator,
} from '../../components/RequestForm/Validators';
import {Loader} from '@progress/kendo-react-indicators';
import {AddWhoWillSee} from '../../components/RequestForm/AddWhoWillSee';
import { DropdownWorkers } from '../../components/RequestForm/DropdownWorkers';
import DropDownTreeCustom from '../../components/PublicacionReportes/dropDownThree/DropDownTreeCustom';
import CustomUploadField from '../../components/PublicacionReportes/CustomUploadField/CustomUploadField';
import {Alert} from '@mui/material';
import axios from 'axios';
import { FormCheckbox,FormDatePicker,
	FormInput,
	FormMultiSelect,
	FormRadioGroup,
	FormTextArea, } from '../../components/RequestForm/form-components';
import { yes_no } from '../../components/RequestForm/data';
import { FormInputKeywords } from '../../components/PublicacionReportes/InputKeywords/InputKeywords';
import { FormInputReport } from '../../components/PublicacionReportes/InputRouteReport/InputRouteReport';
import { FormInputReportV2 } from '../../components/PublicacionReportes/InputRouteReport/InputRouteReportV2';
import { ScheduleField } from '../../components/PublicacionReportes/ScheduleField/ScheduleField';
import useAxios from '../../hooks/UseAxios';
import useAxiosPut from '../../hooks/UseAxiosPut';
import { baseURL } from '../../../Config';

export const Step2Reporteria = () => {
	const [isLoading, setIsLoading] = useState(false);
	
	const [data, setData] = useState([
		{
			baseDatos: 'db_nubemultitenantchile',
			tabla: null,
		},
		{
			baseDatos: 'db_nubemultitenantmexico',
			tabla: null,
		},
	]);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const response = await axios.get(`${baseURL}/api/v1/helpDesk/reading_databricks`);
				const data = response.data;
				setData(data);
				// Process the data here
			} catch (error) {
				// Handle the error here
				throw new Error(error, "Error al cargar la información de las tablas de dataBricks");
			}finally{
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

	const [enableOtherPanel, setEnableOtherPanel] = useState(false);
	const handleChangeChild = (event) => {
		setEnableOtherPanel(event.value);
	};
	const [enableOtherPanelYes, setEnableOtherPanelYes] = useState(false);
	const [enableReplaceReport, setEnableReplaceReport] = useState(false);
	const [labelNameReport, setlabelNameReport] = useState(
		{label:'¿Nombre del reporte?',
		hint:'Así quedará registrado el nombre de la pestaña que saldrá en DataSmart'}
		);
	const handleChangeReplace = (event) => {
		if (event.value) {
			setEnableReplaceReport(true);
			setlabelNameReport({label:'¿Nombre del reporte?',hint:'Así quedará registrado el nombre de la pestaña que saldrá en DataSmart'});
		} else {
			setEnableReplaceReport(false);
			setlabelNameReport({label:'Nombre del reporte que sustituira, debe ser el nombre con el que se almacenó en el power BI embedded, ese nombre lo encuentra en el Control Center',hint:'Coloque el nombre del reporte que aparece en el Control Center'});
		}
	};
	const handleChangeChildYes_No = (event) => {
		if (event.value) {
			setEnableOtherPanelYes(true);
		} else {
			setEnableOtherPanelYes(false);
		}
	};
	const [chossedSubArea, setChossedSubArea] = useState([])
	const [chossedCargos, setChossedCargos] = useState(['user'])
	const{responseData:ListOfCargos, statusResponse, isLoading:isLoadingCargos, error, executePut}=useAxiosPut(`${baseURL}/api/v1/helpDesk/ListOfCargos`)
	const handleSubArea=(event) => {
	    executePut({idArea:event.value})
	}
	const{data:listArea, isLoading:isLoadinglistArea, error:ErrorListArea}=useAxios(`${baseURL}/api/v1/helpDesk/AreasEmployees`)
	useEffect(() => {
		if(ListOfCargos){
	  		setChossedCargos(ListOfCargos)}
	}, [ListOfCargos])
	


	return (
		<div>
			<span className={"k-form-separator"} />
			<Field
				key={'requestDate'}
				id={'requestDate'}
				name={'requestDate'}
				label={'Fecha de registro:'}
				component={FormInput}
				disabled={true}
			/>
					<Field
			key={'isNewReport'}
			id={'isNewReport'}
			onChange={handleChangeReplace}
			name={'isNewReport'}
			label={'¿El reporte que va a cargar es Nuevo?'}
			layout={'horizontal'}			
			component={FormRadioGroup}
			data={yes_no}
			validator={RadioButtonValidator}
		/>
			<Field
				key={'reportName'}
				id={'reportName'}
				name={'reportName'}
				label={labelNameReport.label}
				placeholder={'Escriba el nombre'}
				hint={
					labelNameReport.hint
				}
				component={FormInput}
				validator={!enableReplaceReport?requiredValidator:reportValidator}
			/>
			<Field
				key={"reportFile"}
				id={"reportFile"}
				name={"reportFile"}
				label={"report file"}
				optional={false}
				hint={"Upload your report here"}
				restrictions={{
					allowedExtensions: [".pbix"],
				}}
				component={CustomUploadField}
				validator={requiredValidator}
			/>
			<Alert severity="info">
			Recuerda que el nombre con el que se almacenará tu reporte en Power BI corresponderá al nombre del archivo que subas.
					</Alert>
			<Field
				key={'validatedwithBIteam'}
				id={'validatedwithBIteam'}
				name={'validatedwithBIteam'}
				onChange={handleChangeChild}
				label={'Esta validado con el equipo de BI?'}
				component={FormCheckbox}
				value={enableOtherPanel}
			/>
			{enableOtherPanel && (
				<>
					<Field
						key={'withwhomBIteam'}
						id={'withwhomBIteam'}
						name={'withwhomBIteam'}
						label={'¿Con quién lo revisaste?'}
						component={DropdownWorkers}
						validator={userNameValidator}
					/>
					<Field
						key={'whenCheckBIteam'}
						id={'whenCheckBIteam'}
						name={'whenCheckBIteam'}
						label={'¿Cuándo lo revisaste?'}
						component={FormDatePicker}
						validator={userNameValidator}
					/>
				</>
			)}
			<span className={"k-form-separator"} />
			<div>
				<h4 style={{marginTop: '10px'}}> Acceso al Reporte</h4>
			</div>
			<div>
				<Field
					key={'isRestricted'}
					id={'isRestricted'}
					name={'isRestricted'}
					label={'El reporte es restringido?'}
					layout={'horizontal'}
					onChange={handleChangeChildYes_No}
					component={FormRadioGroup}
					data={yes_no}
					validator={RadioButtonValidator}
				/>
				{enableOtherPanelYes && (
					<Alert severity="warning">
						Recuerda que los permisos se tomaran de acuerdo a las áreas y cargos
						de las personas que veran la información
					</Alert>
				)}
			</div>
			<Field
				key={'areas'}
				id={'areas'}
				name={'areas'}
				label={'Especifique las Áreas que Accederán al Reporte'}
				component={FormMultiSelect}
				data={listArea}
				onChange={handleSubArea}
				validator={requiredValidator}
			/>
			<Field
				key={'requestViewInf'}
				id={'requestViewInf'}
				name={'requestViewInf'}
				data={chossedCargos}
				label={
					'Ingresa los cargos de los usuarios que Accederán al Reporte'
				}
				component={FormMultiSelect}
				validator={WhoWillSeeValidator}
			/>
			
			<div>
				<h4 style={{marginTop: '10px'}}>Fuente de Información</h4>
			</div>

			{isLoading ? (
				<Loader size="large" type="converging-spinner" />
			) : (
				<Field
					key={'lineage'}
					id={'lineage'}
					name={'lineage'}
					label={'Linaje de la información: ¿De dónde viene?'}
					placeholder={'Base de datos, Array tablas'}
					hint={'escoge la fuente de información, no puede ser Excel'}
					component={DropDownTreeCustom}
					validator={requiredValidator}
					data={data}
				/>
			)}
			<div>
				<h4 style={{marginTop: '10px'}}>Indicadores</h4>
			</div>
			<Field
			key={'requestUse'}
			id={'requestUse'}
			name={'requestUse'}
			label={'¿Cuál es el objetivo del Reporte?'}
			placeholder={"Ingresa una descripción del uso u objetivo que le darás al Reporte"}
			component={FormTextArea}
			validator={requiredValidator}
		/>
			<Field
				key={'keyword'}
				id={'keyword'}
				name={'keyword'}
				label={'Palabras claves'}
				placeholder={'ejemplo... #churn'}
				hint={'Palabras claves'}
				component={FormInputKeywords}
				validator={requiredValidator}
			/>
			<Field
				key={'schedule'}
				id={'schedule'}
				name={'schedule'}
				label={'Escribe el cronograma de actualización del Reporte'}
				component={ScheduleField}
				validator={requiredValidator}
			/>
			
			<br />
			<div>
				<h4 style={{marginTop: '10px'}}>¿Dónde quieres ubicar el reporte en DataSmart?</h4>
			</div>
			<Alert severity="info">
						Deberás ir separando por niveles, por ejemplo Reportes por País click en el botón de agregar luego 'Marketing' botón agregar y así sucesivamente
					</Alert>
			<Field
				key={'possibleRoute'}
				id={'possibleRoute'}
				name={'possibleRoute'}
				label={'Escribe la Ruta del Reporte'}
				placeholder={'Escoge parte por parte de la ruta'}
				component={FormInputReportV2}
				validator={RouteValidator}
			/>
			
			
		</div>
	);
};
