import React, {useState, useEffect} from 'react';
import './Navbar.css';
import NavbarItems from './NavbarItems.js';
// import { FiChevronRight } from "react-icons/fi";


const menuItems=[
  {name: 'Home', exact: true, to: "/", iconName: 'home'},
  {name: 'Reportes', exact: true, to: '/Reports', iconName: 'reports', subMenus: [{name: 'Sales & Marketing', to:'/Reports/ReportsSales'},{name: 'Customer Success', to:'/Reports/ReportsCustomer'}, {name: 'Finance', to:'/Reports/ReportsFinance'}, {name: 'Human Resources', to: '/Reports/ReportsHR'}, {name: 'Tech', to: '/Reports/ReportsTech'}]},
  {name: 'Aprendizaje', to: "/Training", iconName: 'aprendizaje', subMenus: [{name: 'Business Intelligence', to:'/Learning/LearningBI'},{name: 'Data Science', to:'/Learning/LearningDS'}, {name: 'Data Engineering' , to:'/Learning/LearningDE'}, {name: 'Soft Skills', to:'/Learning/SoftSkills'}]},
  {name: 'Gobierno de Datos', to: "/DataGovernance", iconName: 'datGov',  subMenus: [{name: 'Diccionario de Datos', to:'/DataGovernance/Dictionary'},{name: 'Documentacion', to:'/DataGovernance/Documentation'}, {name: 'Organigrama', to:'/DataGovernance/OrgChart'}, {name: 'Procesos', to:'/DataGovernance/Processes'}]},
  {name: 'Mesa    de Ayuda', to: "/HelpDesk", iconName: 'helpdesk'},
  {name: 'Herramientas', to: "/Tools", iconName: 'tools',  subMenus: [{name: 'Data Mart', to: '/Tools/DataMart'},{name: 'Segmentacion Siigo', to: '/Tools/Segmentacion'}, {name: 'Recomendaciones Comerciales', to: '/Tools/Wayfinder'}, {name: 'Status del Producto', to: '/Tools/ProductStatus'}, {name: 'Presupuesto', to: '/Tools/Budget'}]},
  {name: 'Compass', to: "/Compass", iconName: 'compass',  subMenus: [{name: 'Ware', to: '/Compass/Ware'}, {name: 'Sales Forecast', to: '/Compass/SalesForecast'}, {name: 'Lending', to: '/Compass/Lending'}, {name: 'Financial Advisor', to: '/Compass  /FinancialAnalysis'}]}
];

function Navbar(props) {
  const [inactive,setInactive]= useState(true);
  const [selected, setSelected] = useState(null);
  useEffect(()=>{
    if(inactive)
      {document.querySelectorAll('.sub-menu').forEach(el=>{
        el.classList.remove('active');
      });
    }
    props.onCollapse(inactive)
  },[inactive]);

  return (
        <div className={`Navbar ${inactive ? "inactive":""}`}>
          {/* <div className='divider'></div>
          <div className='top-section'>
              <FiChevronRight onClick={()=> setInactive(!inactive)} className='logo'/>
          </div>
           */}
          
          


          <div className='main-menu-ds'>
            <ul>
              {menuItems.map((menuItem, index) =>(
                // <div data-tip={menuItem.name} data-for='iconTooltip' >
                  <NavbarItems
                    key={index}
                    name={menuItem.name}
                    to={menuItem.to}
                    setSelected={setSelected}
                    selected={selected}
                    iconName={menuItem.iconName}
                  />
                //   <ReactTooltip id='iconTooltip' place='left' type='light'/>
                // </div>
              ))}
            </ul>
          </div>
        </div>
  )
}

export default Navbar