import {FieldWrapper} from '@progress/kendo-react-form';
import {Upload} from '@progress/kendo-react-upload';
import {Label, Error, Hint} from '@progress/kendo-react-labels';
import {useRef, useState} from 'react';
import axios from 'axios';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { useParams } from 'react-router-dom';
import { Loader } from '@progress/kendo-react-indicators';
import { baseURL } from '../../../../Config';

const CustomUploadField = (fieldRenderProps) => {
	const {
		value,
		id,
		optional,
		label,
		hint,
		folderName,
		validationMessage,
		touched,
		restrictions,
		...others
	} = fieldRenderProps;
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';
	const user = sessionStorage.getItem('usr');
	const area = sessionStorage.getItem('area');
	const subArea = sessionStorage.getItem('subarea');
	const [porcent, setPorcent] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [Errorarchivo, setErrorarchivo] = useState(null)
	const TypeRequest = useParams().type;

	const onRemove = (event) => {
		setIsLoading(false)
		fieldRenderProps.onChange({
			value: null,
		});
	};
	const onProgress = (event,number) => {
		setPorcent(number.loaded/number.total*100)
	};
	const onAdd = async (event) => {
		setIsLoading(true)
		let result= await onSaveRequest(event.newState, null, onProgress);
		if(result){	
		fieldRenderProps.onChange({
			value: event.newState,
		});
		setIsLoading(false)
	}
	};
	const onStatusChange = (event) => {	
		fieldRenderProps.onChange({
			value: event.newState,
		});
	};

	const requestIntervalRef = useRef({});
	const progressRef = useRef({});

	const onSaveRequest = (files, _, onProgress) => {
		const file = files[0].getRawFile();
		const uid = files[0].uid;
		progressRef.current[uid] = 0;
		setIsLoading(true)
		const formData = new FormData();
		formData.append('file', file);

		const config = {
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				progressRef.current[uid] = percentCompleted;
				onProgress(uid, {
					loaded: progressEvent.loaded,
					total: progressEvent.total,
				});
			},
		};
		let folder = TypeRequest;
		let Validextension= restrictions.allowedExtensions;
		if(TypeRequest!=='solicitud de Reporte'){
			folder=`${'temporalDataSmart'}/${files[0].name}`;
		}
		//garc804536@siigo.com
		if(Validextension.includes(files[0].extension)){
			// console.log("userID:", userID,"type:", typeof(userID));
			// console.log("folder:", folder,"type:",typeof(folder));
			// console.log("formData:", formData,"type:",formData);
			//  console.log("config:", config,"type:",config);
		return axios
			.post(`${baseURL}/api/v1/FileManagement/files?id=${files[0].uid}&folder=${folder}&extension=${files[0].extension}`, formData, config)
			.then((response) => {
				console.log('Archivo subido con éxito:', response.data);
				setErrorarchivo(null)
				return true;
			})
			.catch((error) => {
				console.error('Error al subir el archivo:', error);
				setPorcent(null)
				setIsLoading(false)
				setErrorarchivo('Error al subir el archivo')
				throw error;
			});
		}else{
			setErrorarchivo(`El archivo debe ser de tipo ${Validextension.join(', ')}`)
			setPorcent(null)
			setIsLoading(false)
			return false
		}
	};

	const onCancel = (uid) => {
		delete progressRef.current[uid];
		clearInterval(requestIntervalRef.current[uid]);
	};
	

	function onRemoveRequest(files, options) {
		const currentFile = files[0];
		const uid = currentFile.uid;
		return new Promise((resolve) => {
			resolve({
				uid: uid,
			});
		});
	}
	return (
		<FieldWrapper>
			<Label
				id={labelId}
				editorId={id}
				optional={optional}
				className="k-form-label">
				{label}
			</Label>
			<div className={'k-form-field-wrap'}>
			{isLoading && <Loader type="infinite-spinner" size="medium" />}
				<Upload
					multiple={false}
					autoUpload={false}
					restrictions={restrictions}
					ariaDescribedBy={`${hintId} ${errorId}`}
					ariaLabelledBy={labelId}
					showActionButtons={false}
					id={labelId}
					editorId={id}
					optional={optional}
					files={value}
					onAdd={onAdd}
					onRemove={onRemove}
					onProgress={onProgress}
					onStatusChange={onStatusChange}
					onCancel={onCancel}
					saveMethod={'POST'}
					withCredentials={false}
					saveUrl={onSaveRequest}
					removeUrl={onRemoveRequest}
					{...others}
				/>
				
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
				{Errorarchivo&&<Error>{Errorarchivo}</Error>}
			</div>
		</FieldWrapper>
	);
};
export default CustomUploadField;
