import { configureStore } from '@reduxjs/toolkit'
import viewBIReducer from './viewBISlice'
import renewalsTreeReducer from './renewalsTreeReducer'
import TNBReducer from './TNBSlice'
import siignalReducer from './siignalReducer'
import whatsAppConnectReducer from './whatsAppConnect'
import TimeReportingReducer from './TimeReportingSlice'
import dayOperationReducer from './dayOperationReducer'
import credencialesReducer from './credencialesReducer'
import helpDeskTicktReducer from './helpDeskTicketSlice'
import accessPermissionsReducer from './AccessPermissions'
import ControlCenterReducer from './ControlCenterReducer'
import  NotificationsReduce from './notificationsReducer'

export default configureStore({
  reducer: {
    Notifications: NotificationsReduce,
    ControlCenter: ControlCenterReducer,
    accessPermissions: accessPermissionsReducer,
    credenciales: credencialesReducer,
    DayOperation: dayOperationReducer,
    viewBI: viewBIReducer,
    renewalsTree: renewalsTreeReducer,
    TNB:TNBReducer,
    siignal: siignalReducer,
    whatsAppConnect: whatsAppConnectReducer,
    TimeReporting:TimeReportingReducer,
    helpDeskTicket:helpDeskTicktReducer
  },
})