
import { useState } from "react";
import { GoHome } from "react-icons/go";
import { PiChartPieSliceDuotone } from "react-icons/pi";
import { IoSchoolOutline } from "react-icons/io5";
import { AiOutlineBank } from "react-icons/ai";
import { LiaQuestionCircle } from "react-icons/lia";
import { LiaCompassSolid } from "react-icons/lia";
import { VscTools } from "react-icons/vsc";


import {NavLink, useNavigate} from 'react-router-dom'


const DynamicFaIcon = ({ name}) => {
  const options = {
    home: <GoHome style={{ color: 'black', fontSize: '34px' }} />,
    reports: <PiChartPieSliceDuotone style={{ color: 'black', fontSize: '34px' }} />,
    aprendizaje: <IoSchoolOutline style={{ color: 'black', fontSize: '34px' }} />,
    datGov: <AiOutlineBank style={{ color: 'black', fontSize: '34px' }} />,
    helpdesk: <LiaQuestionCircle style={{ color: 'black', fontSize: '37px' }} />,
    tools: <VscTools style={{ color: 'black', fontSize: '31px' }} />,
    compass: <LiaCompassSolid style={{ color: 'black', fontSize: '37px' }} />
  };
    return options[name] || null;
  };

function NavbarItems(props) {
    const navigate=useNavigate();
    const {name, subMenus, iconName, to, setSelected,selected}= props;
    const [expand, setExpand]= useState(false)

    const handleClick = (name,to) => {
      navigate(to)
      setSelected(name);
    }

  return (
    <li key={name} className={`menu-item ${selected===name ? 'selected' : ''}`} onClick={()=>{handleClick(name,to)}} style={{display:'flex',justifyContent:'center'}}>

            <div className='item-icon'>
                <DynamicFaIcon name={iconName}/>
            </div>
            <p>{name}</p>
            <span onClick={()=>setExpand(!expand)}>
                {name}
            </span>

        {subMenus && subMenus.length>0 ? (
                <ul className={`sub-menu-ds ${expand ? "" : "active"}`}>
                    {subMenus.map((menu,index)=> (
                            <li key={index}>
                                <NavLink to={menu.to}>{menu.name}</NavLink>
                            </li>
                        ))}
                </ul>
            ):null}
    </li>
  );
};

export default NavbarItems
