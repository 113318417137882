
import { useEffect, useState } from 'react';
import CronScheduler from './CronScheduler';
import { useDispatch, useSelector } from 'react-redux';
import { selectUrl } from '../../../../../../redux/viewBISlice';
import { selectCredencialesUsr } from '../../../../../../redux/credencialesReducer';
import { baseURL } from '../../../../../../Config';
import useAxiosPost from '../../../../../../components/ViewCustomer/Hook/useAxiosPost';
import useAxiosPut from '../../../../../../HelpDesk/hooks/UseAxiosPut';
import { setSubMenu } from '../../../../../../redux/ControlCenterReducer';

const MainScheduleR = ({setToast}) => {
  const UrlReport = useSelector(selectUrl);
	const email = useSelector(selectCredencialesUsr);
  const [isPutExecuted,setIsPutExecuted]=useState(false)
  const dispatch=useDispatch()
	const {
		data: dataFromReport,
		loading,
		postData,
	} = useAxiosPost(`${baseURL}/api/v1/pbi/Reports/metadata`);

  const {responseData, statusResponse, isLoading:loadingPut, error, executePut}=useAxiosPut(`${baseURL}/api/v1/pbi/Reports/metadataUpdate`)
  useEffect(() => {
		postData({
			email: email,
			GetAtribute: {
				filter: {route: UrlReport},
				fields: ['schedule']},
		});
	}, [])
  const [cronExpression, setCronExpression] = useState('');
  useEffect(() => {
    if (dataFromReport?.schedule !== undefined) {
      setCronExpression(dataFromReport.schedule);
    }
  }, [dataFromReport]);
  const handleCronChange = async (newCron) => {
   if(newCron !== null){
    await executePut({
			route: UrlReport,
			filter: 'fields',
			fields: {schedule: newCron},
			user: email,
		});
		setIsPutExecuted(true);	
  }
    setCronExpression(newCron);
  };
  useEffect(() => {
		if (isPutExecuted) {
			if (statusResponse === 200) {
				setToast({
					type: 'success',
					message: 'Cambios guardados correctamente',
					snackbarOpen: true,
				});
				
			} else {
				setToast({
					type: 'error',
					message: 'Error al guardar los cambios',
					snackbarOpen: true,
				});
				console.log(error);
			}
	
			dispatch(setSubMenu('Updates'));
			setIsPutExecuted(false);
		}
	}, [statusResponse, isPutExecuted]);

  return (
    <div style={{ padding: '20px' }}>

      <CronScheduler value={cronExpression} onChange={handleCronChange} />
      <p>Cron Expression: {cronExpression}</p>
    </div>
  );
};

export default MainScheduleR;
