import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../Config';

const initialState = {
    hasAccess: {},
    loading: false,
    error: null,
    superPermissons:false,
};

const accessPermissionsSlice = createSlice({
    name: 'accessPermissions',
    initialState,
    reducers: {
        // Reducer para manejar el inicio de una solicitud de acceso
        requestAccess(state) {
            state.loading = true;
            state.error = null;
        },
        // Reducer para manejar el éxito en la solicitud de acceso
        accessGranted(state,action) {
            state.hasAccess = action.payload;
            state.loading = false;
            state.error = null;
        },
        // Reducer para manejar el error en la solicitud de acceso
        accessDenied(state, action) {
            state.hasAccess = false;
            state.loading = false;
            state.error = action.payload;
        },
        // Reducer para reiniciar el estado
        resetAccess(state) {
            state.hasAccess = false;
            state.loading = false;
            state.error = null;
        },
        SuperAccess(state,action){
            state.superPermissons = action.payload;
        },
        askPermission(state,action){
            state.hasAccess = action.payload;
        }
    },
});
// Thunk para obtener los permisos de acceso
export const getAccessPermissions = ()=> {
    return async (dispatch,getState) => {
    try {
        dispatch(requestAccess());
        const response = await axios.get(`${baseURL}/api/v1/Users/getAccessPermissions`);
        dispatch(accessGranted(response.data));
    } catch (error) {
        dispatch(accessDenied(error.message));
    }};
};


export const { requestAccess, accessGranted, accessDenied, resetAccess } = accessPermissionsSlice.actions;
export const { SuperAccess, askPermission } = accessPermissionsSlice.actions;
export const selectAccessPermissions = state => state.accessPermissions.hasAccess;

export default accessPermissionsSlice.reducer;
