import ChunkBar from './ChunkBar';
import {useSelector} from 'react-redux';
import {UpdatesTable} from '../UpdatesTable/UpdatesTable';
import {selectlastUpdate, selectSubMenu} from '../../../../redux/ControlCenterReducer.js';
import './SecondaryDisplay.css';
import {KPIs} from '../slices/fifthSlice/KPIs.js';
import GrantPermissions from '../slices/sixthSlice/Permissions.js';
import { LinajeMain } from '../slices/sixthSlice/LinajeModule/LinajeMain.jsx';
import { FormInit } from '../slices/sixthSlice/LinajeModule/formulario/FormInit.js';
import MainScheduleR from '../slices/sixthSlice/scheduleReports/mainScheduleR.js';

export const SecondaryDisplay = ({expandChange, datasetId,setToast}) => {
	let dinamicPanel = useSelector(selectSubMenu);
	let Data = useSelector(selectlastUpdate) || {
		extendedStatus: '',
		startTime: '',
		endTime: '',
		refreshType: '',
		requestId: '',
		statusArray: [],
	};
	const {statusArray} = Data;

	const SubMenu = (menu) => {
		switch (menu) {
			case 'Horarios de Ejecución':
				return <MainScheduleR setToast={setToast}/>;
			case 'Updates':
				return (
					<>
						<ChunkBar statuses={statusArray} data={Data} />
						<UpdatesTable expandChange={expandChange} datasetId={datasetId} />
					</>
				);
			case 'KPIs':
				return <KPIs />;
			case 'Permisos del Reporte':
				return <GrantPermissions setToast={setToast} />;
			case 'Linaje':
				return <LinajeMain setToast={setToast}/>
			case 'Descripción':
				return <FormInit label={menu} setToast={setToast}/>
			case 'Tipo de reporte':
				return <FormInit label={menu} setToast={setToast}/>
			case 'Titulo del Reporte':
				return <FormInit label={menu} setToast={setToast}/>
			default:
				return (
					<>
						<ChunkBar statuses={statusArray} data={Data} />
						<UpdatesTable expandChange={expandChange} datasetId={datasetId} />
					</>
				);
		}
	};

	return (
		<div className="containerSecondaryDisplay">{SubMenu(dinamicPanel)}</div>
	);
};
