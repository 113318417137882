import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';

const DialogItem = ({title,visibleDialog,toggleDialog,handleDialogResponse }) => {

	return (
		<div>
			{visibleDialog && (
				<Dialog title={title||'Please Confirm'} onClose={toggleDialog}>
					<p
						style={{
							margin: '25px',
							textAlign: 'center',
						}}>
						Are you sure you want to continue?
					</p>
					<DialogActionsBar>
						<Button type="button" onClick={()=>{handleDialogResponse(false)}}>
							No
						</Button>
						<Button type="button" onClick={()=>{handleDialogResponse(true)}}>
							Yes
						</Button>
					</DialogActionsBar>
				</Dialog>
			)}
		</div>
	);
};
export default DialogItem;
