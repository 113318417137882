import React, { useEffect } from 'react';
import ReusableForm from './ReusableForm';
import {typeOfReport} from '../../../../../../../HelpDesk/components/RequestForm/data';
import { baseURL } from '../../../../../../../Config';
import useAxiosPost from '../../../../../../../components/ViewCustomer/Hook/useAxiosPost';
import { useSelector } from 'react-redux';
import { selectUrl } from '../../../../../../../redux/viewBISlice';
import { selectCredencialesUsr } from '../../../../../../../redux/credencialesReducer';
import { Skeleton } from '@mui/material';

export const FormInit = ({label,setToast}) => {
    const UrlReport = useSelector(selectUrl);
	const email = useSelector(selectCredencialesUsr);

	const capitalizeWords = (str) => {
		return str.replace(/\b\w/g, (char) => char.toUpperCase());
	};

	const options = typeOfReport.map((report) => ({
		value: report,
		label: capitalizeWords(report),
	}));

	const fields = {
		Descripción: [
			{type: 'textarea', name: 'reportDescription', label: 'Description'},
		],
		'Tipo de reporte': [
			{
				type: 'select',
				name: 'itemType',
				label: 'Tipo de Reporte',
				options: options,
			},
		],
        'Titulo del Reporte': [
            {
                type: 'text',
                name: 'reportTitle',
                label: 'Título del reporte',
            },
        ],
	};
    const {
		data,
		loading,
		error: erroPermissions,
		postData,
	} = useAxiosPost(`${baseURL}/api/v1/pbi/Reports/metadata`);
    useEffect(() => {
		postData({
			email: email,
			GetAtribute: {
				filter: {route: UrlReport},
				fields: [fields[label][0]['name']]},
		});
	}, [label])
    if (loading) {
                    return <Skeleton variant="rectangular" width="770px" height="200px" />;
                }        
	return (
		<div style={{marginTop:'40px'}}>
			<h5>{`Editar ${label}`}</h5>
			<ReusableForm
                setToast={setToast}
                defaultValue={Object.values(data)[0]}
                email={email}
                UrlReport={UrlReport}
				fieldConfig={fields[label]}
				endpoint={`${baseURL}/api/v1/pbi/Reports/metadataUpdate`}
			/>
		</div>
	);
};
